import { queryAPI } from '../../utils/queryApi';

const updateUserEmailMutation = /* GraphQL */ `
  mutation($userId: ID!, $newEmail: String!) {
    updateUserEmail(id: $userId, newEmail: $newEmail)
  }
`;

export const updateClientEmailQuery = async (
  userId: string,
  newEmail: string
) => {
  await queryAPI(updateUserEmailMutation, {
    userId,
    newEmail,
  });
};

export const updateClientEmail = async (userId: string, newEmail: string) => {
  try {
    await updateClientEmailQuery(userId, newEmail);
  } catch (err) {
    console.log('Email did not update correctly please contact admin');
  }
};
