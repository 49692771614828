import React, { ReactElement, useEffect, useState } from 'react';
import axios from 'axios';
import { StyledBlueButton } from '../Button/Button';
import styled from 'styled-components';
import { ThemeColors } from '../Theme/colors';

type Props = {
  email: string;
};

async function iterableUserIsForgotten(email: string) {
  const { data } = await axios.get(
    `${
      process.env.REACT_APP_ITERABLE_FORGOTTEN_STATUS_LAMBDA_URL
    }?email=${encodeURIComponent(email)}`
  );

  return data.forgotten;
}

async function unforgetUser(email: string) {
  return await axios.delete(
    process.env.REACT_APP_ITERABLE_FORGOTTEN_STATUS_LAMBDA_URL ?? '',
    {
      data: { email },
    }
  );
}

type WrapperProps = {
  theme: ThemeColors;
  state: 'idle' | 'error' | 'success';
};

const Wrapper = styled.div`
  flex: 1 0 100%;
  padding: 8px 16px;
  background-color: ${({ theme, state }: WrapperProps): string => {
    switch (state) {
      case 'error':
        return '#eb5743';
      case 'success':
        return theme.vigourZest;
      default:
        return theme.warningRed;
    }
  }};
  color: ${({ theme, state }: WrapperProps): string => {
    switch (state) {
      case 'error':
        return theme.white;
      case 'success':
        return theme.bodyCopy;
      default:
        return theme.white;
    }
  }};
  margin-bottom: 32px;
  font-size: 16px;
  line-height: 1.4;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ClientIterableSection = ({
  email,
}: Props): ReactElement | null => {
  const [forgotten, setForgotten] = useState(false);
  const [unforgetting, setUnforgetting] = useState(false);
  const [unforgettingError, setUnforgettingError] = useState(false);
  const [unforgot, setUnforgot] = useState(false);

  useEffect(() => {
    iterableUserIsForgotten(email).then((forgotten) => {
      setForgotten(forgotten);
    });
  }, [email]);

  async function handleUnforget() {
    setUnforgetting(true);

    try {
      await unforgetUser(email);

      setUnforgot(true);
    } catch (err) {
      setUnforgettingError(true);
    } finally {
      setUnforgetting(false);
    }
  }

  return forgotten ? (
    <Wrapper
      state={unforgot ? 'success' : unforgettingError ? 'error' : 'idle'}
    >
      {!unforgetting && !unforgettingError && !unforgot ? (
        <>
          <p>
            User is forgotten in Iterable due to a GDPR request. They will be
            unable to log in and may experience other app issues.
          </p>
          <StyledBlueButton onClick={handleUnforget}>
            Remove from forgotten list
          </StyledBlueButton>
        </>
      ) : null}
      {unforgetting && !unforgot ? 'Unforgetting...' : null}
      {unforgot
        ? 'Unforget request successful. This will take a few minutes to take effect.'
        : null}
      {unforgettingError
        ? 'Error unforgetting user. Please request technical help in #quickhelp'
        : null}
    </Wrapper>
  ) : null;
};
